import React, { FC, InputHTMLAttributes, useEffect, useMemo, useState } from "react";

import VisibilityIcon from "../../images/VisibilityIcon";
import styles from "./index.module.css";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  wrapperClassName?: string;
  errorClassName?: string;
  error?: string;
  onEnter?: () => void;
  register?: any;
  typeString?: string | null;
}

export const Input: FC<InputProps> = ({
  wrapperClassName = "",
  errorClassName = "",
  className = "",
  error = "",
  onEnter,
  register,
  typeString,
  ...rest
}) => {
  const [type, setType] = useState<string | undefined>(rest.type);
  const [passwordInput, setPasswordInput] = useState(rest.type === "password");
  const [visible, setVisible] = useState<boolean>(rest.type !== "password");
  const [placeholder, setPlaceholder] = useState(rest.placeholder);
  const [defaultValue, setDefaultValue] = useState(rest.defaultValue);

  const phoneTypeHandler = () => {
    if (rest.type === "tel") {
      if (!defaultValue) {
        setDefaultValue("+7 ");
        setPlaceholder("+7 000 000 00 00");
      }
    }
  };

  useEffect(() => {
    phoneTypeHandler();
  }, [rest.type, rest.defaultValue, rest.placeholder]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && onEnter) {
      onEnter();
    } else if (typeString === "int") {
      return /[0-9]/i.test(event.key);
    } else return;
  };

  const onVisible = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    if (visible) {
      setType("text");
    } else {
      setType("password");
    }
  }, [visible]);

  const typeSwitch = useMemo(() => {
    switch (typeString) {
      case "int":
        return "number";
      case "float":
        return "number";
      case "date":
        return "date";
      default:
        return type;
    }
  }, [type, typeString]);

  return (
    <div className={`${styles.inputWrapper} ${wrapperClassName}`}>
      {
        <input
          className={`${styles.input} ${error && styles.error} ${className} `}
          {...rest}
          onKeyDown={handleKeyDown}
          {...register}
          type={typeSwitch}
          defaultValue={defaultValue}
          onClick={(e) => rest.type !== "password" && e.preventDefault()}
          onScroll={(e) => e.currentTarget.blur()}
          onWheel={(e) => e.currentTarget.blur()}
          onScrollCapture={(e) => e.currentTarget.blur()}
          onTouchMove={(e) => e.currentTarget.blur()}
          placeholder={placeholder}
        />
      }
      {error && <div className={`${styles.errorMessage} ${errorClassName}`}>{error === "required" ? "" : error}</div>}
      {passwordInput && (
        <div className={styles.visibleButton}>
          <VisibilityIcon visible={visible} onClick={onVisible} />
        </div>
      )}
    </div>
  );
};
