import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import AsideProvider from "../../generic/providers/AsideProvider";
import { useScreenType } from "../../lib/hooks/useScreenType";
import styles from "./index.module.css";

const MenuGeneratorDemoLayout = () => {
  const screenType = useScreenType();

  if (screenType === "mobile") {
    return (
      <AsideProvider>
        <div className={styles.mobileContainer}>
          <main>
            <Suspense fallback={<div />}>
              <Outlet />
            </Suspense>
          </main>
        </div>
      </AsideProvider>
    );
  }

  return (
    <AsideProvider>
      <div className={styles.container}>
        <div className={styles.aside} style={{ opacity: 0 }} />
        <main>
          <Suspense fallback={<div />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </AsideProvider>
  );
};

export default MenuGeneratorDemoLayout;
