import React, { FC, useEffect, useState } from "react";

import { http } from "../../API";
import OverlayingPopup from "../../UI/OverlayingPopup";
import alarm from "./assets/alarm.svg";
import circle from "./assets/circle.svg";
import egg from "./assets/egg.svg";
import food from "./assets/food.svg";
import spoon from "./assets/soup.svg";
import styles from "./index.module.css";

type Props = {
  weight: string;
  header: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  CPFC: string;
  open: boolean;
};

type Recipe = {
  name: string;
  cooking_time?: string;
  cooking_difficulty?: string;
  ingredients: string;
  preparation: string;
};

type Card = {
  icon: string;
  header: string;
  text: string;
};

const PreparationCard: FC<Card> = ({ icon, header, text }) => {
  return (
    <div className={styles.preparationCard}>
      <img src={icon} />
      <div>
        <h3>{header}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export const RecipeCard: FC<Props> = ({ weight, header, setOpen, CPFC, open }) => {
  const [recipe, setRecipe] = useState<Recipe | null>(null);

  const getRecipe = async () => {
    const response = await http.get(`/api/recipe/${header}`);
    setRecipe(response.data);
  };

  useEffect(() => {
    try {
      getRecipe();
    } catch (e) {
      setRecipe(null);
    }
  }, [header]);

  return (
    <OverlayingPopup isOpen={open} onClose={() => setOpen(false)}>
      <div className={styles.recipeContainer}>
        <div className={styles.recipeHeader}>
          <h2>{recipe?.name}</h2>
          <span onClick={() => setOpen(false)}>✕</span>
        </div>
        <div className={styles.scrollableLayout}>
          <div className={styles.preparationContainer}>
            {recipe?.cooking_time && (
              <PreparationCard icon={alarm} header={"Приготовление"} text={recipe.cooking_time} />
            )}
            {recipe?.cooking_difficulty && (
              <PreparationCard icon={food} header={"Сложность"} text={recipe.cooking_difficulty} />
            )}
            <PreparationCard icon={circle} header={weight + " гр. "} text={CPFC} />
          </div>
          <div className={styles.recipeText}>
            {recipe?.ingredients && (
              <div className={styles.ingredients}>
                <div className={styles.sectionHeader}>
                  <img src={egg} />
                  Ингредиенты
                </div>
                <div className={styles.innerHTML} dangerouslySetInnerHTML={{ __html: recipe?.ingredients }} />
              </div>
            )}
            {recipe?.preparation && (
              <div className={styles.preparation}>
                <div className={styles.sectionHeader}>
                  <img src={spoon} />
                  Приготовление
                </div>
                <div className={styles.innerHTML} dangerouslySetInnerHTML={{ __html: recipe?.preparation }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </OverlayingPopup>
  );
};
