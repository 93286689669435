import React, { FC, useState } from "react";

import placeholder from "./assets/placeholder.webp";
import styles from "./index.module.css";
import { RecipeCard } from "./RecipeCard";

type Props = {
  header: string;
  image: string;
  hasRecipe: boolean;
  portion: {
    weight: number;
    calories: number;
    proteins: number;
    fats: number;
    carbohydrates: number;
  };
};

export const FoodCard: FC<Props> = ({ header, portion, image, hasRecipe }) => {
  const weight = `КБЖУ на ${portion.weight}`;
  const CPFC = `${portion.calories}/${portion.proteins}/${portion.fats}/${portion.carbohydrates}`;
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className={styles.foodCard}>
        <div
          style={
            image === ""
              ? { background: `url(${placeholder})`, backgroundSize: "cover" }
              : { background: `url('${image}')`, backgroundPosition: "center" }
          }
          className={styles.image}
        />
        <div
          className={styles.foodCardDescription}
          style={hasRecipe ? { cursor: "pointer" } : {}}
          onClick={() => hasRecipe && setOpen(true)}
        >
          <div>
            <h3>{header}</h3>
            <p>
              {weight + " гр. "}
              {CPFC}
            </p>
          </div>
          {hasRecipe && <p style={{ fontSize: "20px" }}>❯</p>}
        </div>
      </div>
      <RecipeCard weight={`КБЖУ на ${portion.weight}`} CPFC={CPFC} header={header} setOpen={setOpen} open={open} />
    </>
  );
};
