import React from "react";
import { Outlet } from "react-router-dom";

import { useAuth } from "../layouts/AuthProvider/AuthProvider";
import AxiosProvider from "../layouts/AxiosProvider/AxiosProvider";

const PrivateRoute = (props: { logoutOnError?: boolean }) => {
  const { user } = useAuth();

  return <AxiosProvider {...props}>{user && <Outlet />}</AxiosProvider>;
};

export default PrivateRoute;
