import React, { FC } from "react";

import { FoodCard } from "./FoodCard";
import styles from "./index.module.css";

type Props = {
  time: string;
  dishes: {
    image: string;
    name: string;
    hasRecipe: boolean;
    portion: {
      weight: number;
      calories: number;
      proteins: number;
      fats: number;
      carbohydrates: number;
    };
  }[];
  end?: boolean;
};

export const MenuCard: FC<Props> = ({ time, dishes, end }) => {
  return (
    <>
      <div className={styles.container}>
        <h2>{time === "breakfast" ? "Завтрак" : time === "lunch" ? "Обед" : "Ужин"}</h2>
        <div className={styles.foodCards}>
          {dishes.map((e) => (
            <FoodCard hasRecipe={e.hasRecipe} header={e.name} portion={e.portion} key={e.name} image={e.image} />
          ))}
        </div>
      </div>
      {!end && <hr className={styles.divider} />}
    </>
  );
};
